import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Theme, defaultTheme, GlobalStyle } from './theme';

type ProviderProps = {
  children: React.ReactNode;
  theme?: Theme;
};

export const CompoundProvider: React.FC<ProviderProps> = ({ theme = defaultTheme, children }: ProviderProps) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    {children}
  </ThemeProvider>
);
