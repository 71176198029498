import { createGlobalStyle, FlattenSimpleInterpolation } from 'styled-components';
import { fonts } from './fonts';
import normalize from './normalize';
import srOnly from './sr-only';
import { mediaQueries, breakpoints } from './index';

export interface Theme {
  name: string;
  isDark: boolean;
  color: {
    dark: string;
    light: string;
    gray: string;
    primary: string;
    secondary: string;
    secondaryHover: string;
    success: string;
    warning: string;
    gradient: string;
    lightGray: string;
  };
  radius: {
    button: string;
  };
  font: {
    body: string;
    header: string;
    fallbackFont: string;
  };
  mq: (key: keyof typeof breakpoints) => (style: TemplateStringsArray | string) => FlattenSimpleInterpolation;
}

export const defaultTheme: Theme = {
  name: 'DEFAULT',
  isDark: false,
  color: {
    dark: '#000000',
    light: '#ffffff',
    gray: '#7F7F7F',
    lightGray: '#dedfe1',
    primary: '#9538cc', // pink-purple
    secondary: '#efff75', // neon yellow
    secondaryHover: '#b7d856',
    success: '#A040D2',
    warning: '#A040D2',
    gradient: 'linear-gradient(135deg, #9538cc, #785cdd)',
  },
  radius: {
    button: '3px',
  },
  font: {
    body: "'ReplicaStd', 'Helvetica Neue', sans-serif",
    header: "'ReplicaStd', 'Helvetica Neue', sans-serif",
    fallbackFont: '-apple-system, BlinkMacSystemFont, sans-serif',
  },
  mq: mediaQueries,
};

export const GlobalStyle = createGlobalStyle`

  ${normalize}
  ${fonts}
  ${srOnly}

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
    font-size: 62.5%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-smoothing: antialiased;
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
    line-height: 1.6667;
    letter-spacing: 0.048rem;
    font-family: ${defaultTheme.font.body};
  }

  img {
    max-width: 100%;
  }

  ul, ol {
    padding: 0;
    margin: 0;
  }

  ul {
    list-style-type: none;
  }

  h2 {
    font-size: 6rem;
    font-size: clamp(2rem, 1rem + 4vw, 6rem);
  }
`;
