import { css } from 'styled-components';
import { Theme, defaultTheme } from './theme';
export { CompoundProvider } from './Provider';
export const lightTheme: Theme = { ...defaultTheme, name: 'LIGHT' };
export const darkTheme: Theme = { ...defaultTheme, name: 'DARK', isDark: true };

export const breakpoints = {
  xs: 480,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1400,
};

// There are issues with accessing props inside this function
export const mediaQueries = (key: keyof typeof breakpoints) => {
  return (style: TemplateStringsArray | string) =>
    css`
      @media (min-width: ${breakpoints[key]}px) {
        ${style}
      }
    `;
};

// Output a MQ string instead if needed
export const mq = (key: keyof typeof breakpoints) => {
  return `@media (min-width: ${breakpoints[key]}px)`;
};
