import type { AppProps } from 'next/app';
import { useState, useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { CompoundProvider } from 'theme';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <>
      <CompoundProvider>
        <RecoilRoot>{isMounted && <Component {...pageProps} />}</RecoilRoot>
      </CompoundProvider>
    </>
  );
};

export default MyApp;
